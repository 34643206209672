<script lang="ts">
	export let index = 0;
	const tips = [
		"You can change the gamemode by clicking Wordle Unlimited.",
		"Hard mode is game mode specific. Turning it on in one game mode won't change it on the others.",
		"Double tap or right click a word on the board to learn its definition.",
		"Hard mode can be enabled during a game if you haven't violated the hard mode rules yet.",
		"Double tap or right click the next row to see how many possible words can be played there, if you use all the previous information.",
		"Because words are chosen from the list randomly it is possible to get the same word again.",
		"When you see the refresh button in the top left corner it means a new word is ready.",
		"Everyone has the same wordle at the same time. Your word #73 is the same as everyone elses #73.",
		"There are more valid guesses than possible words, ie. not all 5 letter words can be selected as an answer by the game.",
		"Historical games don't count towards your stats. Historical games are when you follow a link to a specific game number.",
	];
	export const length = tips.length;
</script>

<div class="outer">
	<div class="number">Tip {index + 1}/{tips.length}</div>
	<div class="tip">{tips[index]}</div>
	<svg class="left" on:click={() => (index = (index - 1 + tips.length) % tips.length)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>
	<svg class="right" on:click={() => (index = (index + 1) % tips.length)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>
</div>

<style lang="scss">
	.outer {
		margin: 15px auto;
		padding: 10px 30px;
		max-width: calc(1 * var(--game-width));
		border: solid 1px rgb(200 237 253);
		background: rgb(239 249 253);
		border-radius: 4px;
		color: rgb(1, 67, 97);
		position: relative;
	}
	.number {
		text-align: center;
		font-weight: bold;
		font-size: 1.2em;
		margin-bottom: 10px;
	}
	.left,
	.right {
		cursor: pointer;
		position: absolute;
		border-radius: 50px;
		background: var(--fg-secondary);
		fill: var(--bg-primary);
		height: 35px;
		width: 35px;
		padding: 10px 0;
		top: 50%;
	}
	.left {
		left: 2px;
		transform: translate(-50%, -50%);
	}
	.right {
		right: 2px;
		transform: translate(50%, -50%);
	}
	.tip {
		text-align: center;
		min-height: 70px;
	}
</style>
