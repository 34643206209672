<script lang="ts">
	import { COLS, ROWS } from "../../utils";

	import { Tile } from "../board";
import GameIcon from "../GameIcon.svelte";
	export let visible: boolean;
</script>

<h3>how to play</h3>
<div>Guess the <strong>WORDLE</strong> in {ROWS} attempts.</div>
<div>Each guess should be a valid {COLS} letter word. Hit the Enter key to submit your guess.</div>
<div>
	After each guess, the color will appear on one of the tiles, showing how close it was to the word you predicted.
</div>
<div class:complete={visible} class="examples">
	<div><strong>see example image</strong></div>
	<div class="row">
		<Tile value="e" state="🟩" />
		<Tile value="a" state="🔳" />
		<Tile value="g" state="🔳" />
		<Tile value="e" state="🔳" />
		<Tile value="r" state="🔳" />
	</div>
	<div>The letter <strong>E</strong> can be found in the word and in the right position</div>
	<div class="row">
		<Tile value="l" state="🔳" />
		<Tile value="a" state="🟨" />
		<Tile value="c" state="🔳" />
		<Tile value="e" state="🔳" />
		<Tile value="s" state="🔳" />
	</div>
	<div>The letter <strong>A</strong> is in the word but in the incorrect spot.</div>
	<div class="row">
		<Tile value="m" state="🔳" />
		<Tile value="a" state="🔳" />
		<Tile value="g" state="🔳" />
		<Tile value="i" state="⬛" />
		<Tile value="c" state="🔳" />
	</div>
	<div>The letter <strong>I</strong> is not found in the word anywhere.</div>
</div>
<div>
	With the new features and modes, you can now play infinite words! You can now play an unlimited number of times by switching to infinite mode.
	<br /><br />
	Look in the settings menu <GameIcon><path
	d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"
/></GameIcon> to see some of the additional features that are available.
</div>

<style lang="scss">
	div {
		margin: 14px 0;
	}
	.examples {
		border-top: 1px solid var(--border-primary);
		border-bottom: 1px solid var(--border-primary);
		:global(.row > *) {
			height: 100%;
			aspect-ratio: 1;
		}
		&:not(.complete) :global(.row .back) {
			transition-delay: 0.3s;
		}
	}
	.row {
		height: 48px;
		display: flex;
		gap: 10px;
	}
</style>
